import AboutUs from "../../components/complexes/AboutUs/AboutUs"
import Application from "../../components/complexes/Application/Application"
import CaringOfEnviroment from "../../components/complexes/CaringOfEnviroment/CaringOfEnviroment"
import Contact from "../../components/complexes/Contact/Contact"
import Footer from "../../components/complexes/Footer/Footer"
import Header from "../../components/complexes/Header/Header"
import Intro from "../../components/complexes/Intro/Intro"
import OurBikes from "../../components/complexes/OurBikes/OurBikes"
import styles from "./MainPage.module.scss"

const MainPage = () => {
	return (
		<div className={styles.mainPage}>
			<Header />
			<Intro />
			<CaringOfEnviroment />
			<AboutUs />
			<Application />
			<OurBikes />
			<Contact />
			<Footer />
		</div>
	)
}
export default MainPage
