import './CaringOfEnviroment.scss';
import { ReactComponent as LeafIcon } from "../../../assets/icons/leaf_icon.svg";
import { ReactComponent as TowerIcon } from "../../../assets/icons/caring_tower_icon.svg";
import { ReactComponent as SeaIcon } from "../../../assets/icons/caring_sea_icon.svg";
import { ReactComponent as MaintainIcon } from "../../../assets/icons/caring_maintain_icon.svg";
import { ReactComponent as LineIcon } from "../../../assets/icons/line.svg";
import { useTranslation } from 'react-i18next';

const CaringOfEnviroment = () => {
    const { t } = useTranslation();

    return (
        <div className='container'>
            <div className="caring-of-enviroment__inner">
                <div className="caring-of-enviroment__text">
                    <div>
                        <LeafIcon className="caring-of-enviroment__leaf-icon" />
                        <span className="caring-of-enviroment__title">{t('caringOfEnviroment.title')}</span>
                    </div>
                    <span className="caring-of-enviroment__subtitle">{t('caringOfEnviroment.subtitle')}</span>
                    <LineIcon className="caring-of-enviroment__line" />
                </div>
                <div className="caring-of-enviroment__icons">
                    <TowerIcon />
                    <SeaIcon />
                    <MaintainIcon />
                </div>
            </div>
        </div>
    )
}

export default CaringOfEnviroment;
